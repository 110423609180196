<template>
    <div class="container">
        <div class="relative d-flex flex-column min-h-screen pt-5 sm:pt-0 border-bottom border-2 border-color-resilience">
            <div class="h2 d-flex justify-content-center color-resilience-red">料金プラン</div>
        </div>

        <ul class="nav nav-tabs pt-3 font-s-half-up">
            <li class="nav-item col-4 tab-1">
                <a class="nav-link active tab1_link text-center fs-md-sm" href="#tab-content1" data-bs-toggle="tab">年会費</a>
            </li>
            <li class="nav-item col-4 tab-2">
                <a class="nav-link tab2_link text-center fs-md-sm" href="#tab-content2" data-bs-toggle="tab">年会費の月割</a>
            </li>
            <li class="nav-item col-4 tab-3">
                <a class="nav-link tab3_link text-center fs-md-sm" href="#tab-content3" data-bs-toggle="tab">従量課金</a>
            </li>
        </ul>

        <div class="tab-content pb-5">
            <div id="tab-content1" class="tab-pane active">
                <div class="relative d-flex flex-column min-h-screen pt-5 sm:pt-0 border-bottom border-2 border-color-resilience">
                    <div class="h4 d-flex justify-content-center color-resilience-red ">年会費</div>
                </div>

                <div class="row mt-5 m-md-5">
                    <div class="col-md-4">
                        <table class="table table-bordered border-dark bg-color-white">
                            <tbody>
                                <tr>
                                    <td class="text-center">年会費年度</td>
                                    <td class="text-center">{{ this.annual_charge.year }}</td>
                                    <td class="text-center">年度</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-4">
                        <table class="table table-bordered border-dark bg-color-white">
                            <tbody>
                                <tr>
                                    <td class="text-center">制定日</td>
                                    <td class="text-center">{{ this.$root.dayjs(this.annual_charge.制定日).format('YYYY年MM月DD日') }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="row mx-md-5 mb-5">
                    <div class="col-md-8">
                        <table class="table table-bordered border-dark bg-color-white" id="annual_table">
                            <tbody>
                                <tr>
                                    <td>企業会員</td>
                                    <td class="text-end">{{ this.年会費_企業会員 }}</td>
                                    <td class="text-center">{{ yen_per_year_label }}</td>
                                </tr>
                                <tr>
                                    <td>団体会員</td>
                                    <td class="text-end">{{ this.年会費_団体会員 }}</td>
                                    <td class="text-center"></td>
                                </tr>
                                <tr>
                                    <td>公共会員</td>
                                    <td class="text-end">{{ this.年会費_公共会員 }}</td>
                                    <td class="text-center"></td>
                                </tr>
                                <tr v-if="showFoundingFlg">
                                    <td>クラファン会員</td>
                                    <td class="text-end">クラウドファンディングのリターン</td>
                                    <td class="text-center"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div id="tab-content2" class="tab-pane">
                <div class="relative d-flex flex-column min-h-screen pt-5 sm:pt-0 border-bottom border-2 border-color-resilience">
                    <div class="h4 d-flex justify-content-center color-resilience-red">年会費の月割</div>
                </div>

                <div class="row mx-md-5 mt-5">
                    <div class="col-md-4">
                        <table class="table table-bordered border-dark bg-color-white">
                            <tbody>
                                <tr>
                                    <td class="text-center">年会費年度</td>
                                    <td class="text-center">{{ this.annual_charge.year }}</td>
                                    <td class="text-center">年度</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-4">
                        <table class="table table-bordered border-dark bg-color-white">
                            <tbody>
                                <tr>
                                    <td class="text-center">制定日</td>
                                    <td class="text-center">{{ this.$root.dayjs(this.annual_charge.制定日).format('YYYY年MM月DD日') }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="row mx-md-5 mb-5">
                    <div class="col-md-10">
                        <div class="row my-2">
                            <div class="col-4 col-md-2">
                                <label class="h6 col-form-label">会員種別：</label>
                            </div>
                            <div class="col-6 col-md-3">
                                <select name="試算_会員種別" class="form-select" @change="changeTitle">
                                    <option type="text" v-for="kind in member_kind_list" :key="kind.code" :value="kind.name">{{ kind.name }}</option>
                                </select>
                            </div>
                        </div>
                        <table class="table table-bordered border-dark bg-color-white fs-md-sm">
                            <tbody>
                                <tr>
                                    <td class="text-center">{{ Number(this.annual_charge.year) - 1 }}</td>
                                    <td class="text-center">年</td>
                                    <td class="text-center">10</td>
                                    <td class="text-center">月ご加入</td>
                                    <td class="text-center">（</td>
                                    <td class="text-center">12</td>
                                    <td class="text-center">ヶ月分</td>
                                    <td class="text-center">）</td>
                                    <td class="text-end">{{ this.monthlyInstallment(12, this.title) }}</td>
                                    <td class="text-center">{{ yen_label }}</td>
                                </tr>
                                <tr>
                                    <td class="text-center">{{ Number(this.annual_charge.year) - 1 }}</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">11</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">（</td>
                                    <td class="text-center">11</td>
                                    <td class="text-center">ヶ月分</td>
                                    <td class="text-center">）</td>
                                    <td class="text-end">{{ this.monthlyInstallment(11, this.title) }}</td>
                                    <td class="text-center"></td>
                                </tr>
                                <tr>
                                    <td class="text-center">{{ Number(this.annual_charge.year) - 1 }}</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">12</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">（</td>
                                    <td class="text-center">10</td>
                                    <td class="text-center">ヶ月分</td>
                                    <td class="text-center">）</td>
                                    <td class="text-end">{{ this.monthlyInstallment(10, this.title) }}</td>
                                    <td class="text-center"></td>
                                </tr>
                                <tr>
                                    <td class="text-center">{{ Number(this.annual_charge.year) }}</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">1</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">（</td>
                                    <td class="text-center">9</td>
                                    <td class="text-center">ヶ月分</td>
                                    <td class="text-center">）</td>
                                    <td class="text-end">{{ this.monthlyInstallment(9, this.title) }}</td>
                                    <td class="text-center"></td>
                                </tr>
                                <tr>
                                    <td class="text-center">{{ Number(this.annual_charge.year) }}</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">2</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">（</td>
                                    <td class="text-center">8</td>
                                    <td class="text-center">ヶ月分</td>
                                    <td class="text-center">）</td>
                                    <td class="text-end">{{ this.monthlyInstallment(8, this.title) }}</td>
                                    <td class="text-center"></td>
                                </tr>
                                <tr>
                                    <td class="text-center">{{ Number(this.annual_charge.year) }}</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">3</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">（</td>
                                    <td class="text-center">7</td>
                                    <td class="text-center">ヶ月分</td>
                                    <td class="text-center">）</td>
                                    <td class="text-end">{{ this.monthlyInstallment(7, this.title) }}</td>
                                    <td class="text-center"></td>
                                </tr>
                                <tr>
                                    <td class="text-center">{{ Number(this.annual_charge.year) }}</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">4</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">（</td>
                                    <td class="text-center">6</td>
                                    <td class="text-center">ヶ月分</td>
                                    <td class="text-center">）</td>
                                    <td class="text-end">{{ this.monthlyInstallment(6, this.title) }}</td>
                                    <td class="text-center"></td>
                                </tr>
                                <tr>
                                    <td class="text-center">{{ Number(this.annual_charge.year) }}</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">5</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">（</td>
                                    <td class="text-center">5</td>
                                    <td class="text-center">ヶ月分</td>
                                    <td class="text-center">）</td>
                                    <td class="text-end">{{ this.monthlyInstallment(5, this.title) }}</td>
                                    <td class="text-center"></td>
                                </tr>
                                <tr>
                                    <td class="text-center">{{ Number(this.annual_charge.year) }}</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">6</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">（</td>
                                    <td class="text-center">4</td>
                                    <td class="text-center">ヶ月分</td>
                                    <td class="text-center">）</td>
                                    <td class="text-end">{{ this.monthlyInstallment(4, this.title) }}</td>
                                    <td class="text-center"></td>
                                </tr>
                                <tr>
                                    <td class="text-center">{{ Number(this.annual_charge.year) }}</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">7</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">（</td>
                                    <td class="text-center">3</td>
                                    <td class="text-center">ヶ月分</td>
                                    <td class="text-center">）</td>
                                    <td class="text-end">{{ this.monthlyInstallment(3, this.title) }}</td>
                                    <td class="text-center"></td>
                                </tr>
                                <tr>
                                    <td class="text-center">{{ Number(this.annual_charge.year) }}</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">8</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">（</td>
                                    <td class="text-center">2</td>
                                    <td class="text-center">ヶ月分</td>
                                    <td class="text-center">）</td>
                                    <td class="text-end">{{ this.monthlyInstallment(2, this.title) }}</td>
                                    <td class="text-center"></td>
                                </tr>
                                <tr>
                                    <td class="text-center">{{ Number(this.annual_charge.year) }}</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">9</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">（</td>
                                    <td class="text-center">1</td>
                                    <td class="text-center">ヶ月分</td>
                                    <td class="text-center">）</td>
                                    <td class="text-end">{{ this.monthlyInstallment(1, this.title) }}</td>
                                    <td class="text-center"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div id="tab-content3" class="tab-pane">
                <div class="relative d-flex flex-column min-h-screen pt-5 sm:pt-0 border-bottom border-2 border-color-resilience">
                    <div class="h4 d-flex justify-content-center color-resilience-red">従量課金</div>
                </div>

                <div class="row mt-5 mx-md-5">
                    <div class="col-md-4">
                        <table class="table table-bordered border-dark bg-color-white">
                            <tbody>
                                <tr>
                                    <td class="text-center">制定日</td>
                                    <td class="text-center">{{ this.$root.dayjs(this.pay_per_use.制定日).format('YYYY年MM月DD日') }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row mx-md-5">
                    <label>※従業課金は年度単位（10月～翌年9月）のため月割計算はございません。加入月に年度の費用が請求されます。</label>
                </div>

                <div class="row mt-5 m-md-5">
                    <div class="col-md-3">
                        <table class="table table-bordered border-dark bg-color-white">
                            <tbody>
                                <tr>
                                    <td class="text-center">登録する拠点・支店の数</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-9">
                        <table class="table table-bordered border-dark bg-color-white table-detail fs-md-sm">
                            <tbody>
                                <tr v-for="(branch, index) in pay_per_use_detail_branch" :key="branch.pay_per_use_id">
                                    <td class="text-center">{{ this.formatNumber(branch.開始数) }}</td>
                                    <td class="text-center" v-if="index===0">ヶ所から</td>
                                    <td class="text-center" v-else></td>
                                    <td class="text-center">{{ this.formatNumber(branch.終了数) }}</td>
                                    <td class="text-center" v-if="index===0">ヶ所まで</td>
                                    <td class="text-center" v-else></td>
                                    <td class="text-center" v-if="index===0">1ヶ所につき</td>
                                    <td class="text-center" v-else></td>
                                    <td class="text-end">{{ this.formatNumber(branch.課金額) }}</td>
                                    <td class="text-center text-nowrap" v-if="index===0">{{ yen_per_year_label }}</td>
                                    <td class="text-center" v-else></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="row mt-5 m-md-5">
                    <div class="col-md-3">
                        <table class="table table-bordered border-dark bg-color-white">
                            <tbody>
                                <tr>
                                    <td class="text-center">登録する役員・従業員人数</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-9">
                        <table class="table table-bordered border-dark bg-color-white table-detail fs-md-sm">
                            <tbody>
                                <tr v-for="(employee, index) in pay_per_use_detail_employee" :key="employee.pay_per_use_id">
                                    <td class="text-center">{{ this.formatNumber(employee.開始数) }}</td>
                                    <td class="text-center" v-if="index===0">人から</td>
                                    <td class="text-center" v-else></td>
                                    <td class="text-center">{{ this.formatNumber(employee.終了数) }}</td>
                                    <td class="text-center" v-if="index===0">人まで</td>
                                    <td class="text-center" v-else></td>
                                    <td class="text-center" v-if="index===0">1人につき</td>
                                    <td class="text-center" v-else></td>
                                    <td class="text-end">{{ this.formatNumber(employee.課金額) }}</td>
                                    <td class="text-center text-nowrap" v-if="index===0">{{ yen_per_year_label }}</td>
                                    <td class="text-center" v-else></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="row mt-5 m-md-5">
                    <div class="col-md-3">
                        <table class="table table-bordered border-dark bg-color-white">
                            <tbody>
                                <tr>
                                    <td class="text-center">登録する家族の人数</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-9">
                        <table class="table table-bordered border-dark bg-color-white table-detail fs-md-sm">
                            <tbody>
                                <tr v-for="(family, index) in pay_per_use_detail_family" :key="family.pay_per_use_id">
                                    <td class="text-center">{{ this.formatNumber(family.開始数) }}</td>
                                    <td class="text-center" v-if="index===0">人から</td>
                                    <td class="text-center" v-else></td>
                                    <td class="text-center">{{ this.formatNumber(family.終了数) }}</td>
                                    <td class="text-center" v-if="index===0">人まで</td>
                                    <td class="text-center" v-else></td>
                                    <td class="text-center" v-if="index===0">1人につき</td>
                                    <td class="text-center" v-else></td>
                                    <td class="text-end">{{ this.formatNumber(family.課金額) }}</td>
                                    <td class="text-center text-nowrap" v-if="index===0">{{ yen_per_year_label }}</td>
                                    <td class="text-center" v-else></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="row mt-5 mx-md-5" v-if="showLINE === 'true'">
                    <div class="col-md-3">
                        <table class="table table-bordered border-dark bg-color-white">
                            <tbody>
                                <tr>
                                    <td class="text-center">LINE連携オプション</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-8 pe-0">
                                <table class="table table-bordered border-dark bg-color-white table-line fs-md-sm">
                                    <tbody>
                                        <tr>
                                            <td class="text-center">1会員につき</td>
                                            <td class="text-center">{{ this.formatNumber(pay_per_use_line) }}</td>
                                            <td class="text-center text-nowrap">{{ yen_per_year_label }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-md-5">
                    <label>※LINEと連携しTOTONO-L公式アカウントからご利用のLINEアプリにメッセージが配信されます。安否確認やBCP発動、BCP訓練の連絡等の通知がメールと併用して受け取ることができ、災害時のメール遅延やメールアドレス変更等による通知の未達を補います。</label>
                </div>
                <div class="row mt-5 mx-md-5">
                    <div class="d-flex justify-content-start">
                        <button class="btn btn-resilience-red px-5" @click="showCalcPayPerUse">従量課金の自動計算ツール</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { inject, onMounted, ref, computed } from 'vue';
import { CommonNumberFormat } from '../../function/CommonNumberFormat.js';
import { useEnv } from '../../function/useEnv';
import { YEN_LABEL } from "@/constants";

export default {
    setup(props){
        const dayjs = inject('dayjs');

        const 年会費_企業会員 = ref('');
        const 年会費_団体会員 = ref('');
        const 年会費_公共会員 = ref('');
        const title = ref('');
        const { VITE_SHOW_FOUNDING_FLG, VITE_ENABLE_LINE } = useEnv();
        const showFoundingFlg = ref(VITE_SHOW_FOUNDING_FLG);
        const showLINE = ref(VITE_ENABLE_LINE);

        const yen_label = ref(YEN_LABEL.yen);
        const yen_per_year_label = ref(YEN_LABEL.yen_per_year);

        onMounted(() => {
            年会費_企業会員.value = CommonNumberFormat(props.annual_charge.年会費_企業会員);
            年会費_団体会員.value = CommonNumberFormat(props.annual_charge.年会費_団体会員);
            年会費_公共会員.value = CommonNumberFormat(props.annual_charge.年会費_公共会員);
            title.value = '企業会員';
        });

        const monthlyInstallment = (month, kind) => {
            let amount = 0;
            switch(kind){
                case '企業会員':
                    amount = props.annual_charge.年会費_企業会員;
                    break;
                case '団体会員':
                    amount = props.annual_charge.年会費_団体会員;
                    break;
                case '公共会員':
                    amount = props.annual_charge.年会費_公共会員;
                    break;
                default:
                    amount = props.annual_charge.年会費_クラウドファンディング会員;
                    break;
            }
            return CommonNumberFormat(Math.floor(amount / 12 * month));
        };

        const changeTitle = (e) => {
            title.value = e.target.value;
        }

        const formatNumber = (num) => {
            return CommonNumberFormat(num);
        }

        const showCalcPayPerUse = () => {
            const url = '/calc_pay_per_use';

            let newWindow = window.open(url, '_blank');
        }

        return{
            showFoundingFlg,
            年会費_企業会員,
            年会費_団体会員,
            年会費_公共会員,
            monthlyInstallment,
            title,
            changeTitle,
            formatNumber,
            showLINE,

            showCalcPayPerUse,

            yen_label,
            yen_per_year_label
        };
    },
    props: {
        pay_per_use:{
            type: Object,
        },
        pay_per_use_detail_employee:{
            type: Array,
        },
        pay_per_use_detail_branch:{
            type: Array,
        },
        pay_per_use_detail_family:{
            type: Array,
        },
        pay_per_use_line: {
            type: Number,
        },
        annual_charge:{
            type: Object,
            required: true
        },
        member_kind_list: {
            type: Array,
        },
    }

};
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.tab-1, .tab-2, .tab-3{
    background-color: #fce4d6;
}

.tab-content, #tab-content1, #tab-content1, #tab-content1{
    background-color: #D9D9D9;
}

a:link.tab1_link, 
a:visited.tab1_link, 
a:hover.tab1_link, 
a:active.tab1_link,
a:link.tab2_link, 
a:visited.tab2_link, 
a:hover.tab2_link, 
a:active.tab2_link,
a:link.tab3_link, 
a:visited.tab3_link, 
a:hover.tab3_link, 
a:active.tab3_link{
    color: #b93133;
}

.nav-tabs .tab-1 .nav-link.active,
.nav-tabs .tab-2 .nav-link.active,
.nav-tabs .tab-3 .nav-link.active{
    background-color: #D9D9D9;
    border-color: black black #D9D9D9;
}

.tab-content{
    border-style: solid;
    border-width: 1px;
    border-color: black;
}

#annual_table td:nth-child(1){
    width: 40%;
}

#annual_table td:nth-child(2){
    width: 25%;
}


.table-detail td:nth-child(1),
.table-detail td:nth-child(3),
.table-detail td:nth-child(6),
.table-detail td:nth-child(7)
{
    width: 15%;
}

.table-detail td:nth-child(2),
.table-detail td:nth-child(4){
    width: 10%;
}

.table-line td:nth-child(1){
    width: 40%;
}

.table-line td:nth-child(2){
    width: 30%;
}

.border-estimate {
    border: 1px solid common_colors.colors(resilience-black);
}

.border-estimate-start {
    border-top: 1px solid common_colors.colors(resilience-black);    
    border-bottom: 1px solid common_colors.colors(resilience-black);    
    border-left: 1px solid common_colors.colors(resilience-black);
}

.border-estimate-mid {
    border-top: 1px solid common_colors.colors(resilience-black);    
    border-bottom: 1px solid common_colors.colors(resilience-black);    
}

.border-estimate-end {
    border-top: 1px solid common_colors.colors(resilience-black);    
    border-bottom: 1px solid common_colors.colors(resilience-black);    
    border-right: 1px solid common_colors.colors(resilience-black);
}

@media (max-width: 767px) {
    .fs-md-sm {
        font-size: 0.75em;
    }

    .table-detail td:nth-child(1),
    .table-detail td:nth-child(3),
    .table-detail td:nth-child(6)
    {
        width: 10%;
    }

    .table-detail td:nth-child(2),
    .table-detail td:nth-child(4),
    .table-detail td:nth-child(7){
        width: 15%;
    }

}

</style>