<template>
    <div class="container">
        <div class="relative d-flex flex-column min-h-screen pt-5 sm:pt-0 border-bottom border-2 border-color-resilience">
            <div class="h2 d-flex justify-content-center color-resilience-red">従量課金の自動計算ツール</div>
        </div>

        <!-- <div class="row mt-3">
            <span><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">年会費</label></span>
        </div>
        <div class="row mt-2">
            <div class="col-md-3 offset-md-1"><label class="col-form-label" for="試算_加入予定日">加入予定日</label></div>
            <div class="col-md-3">
                <input type="date" v-model="試算_加入予定日" class="form-control">
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-3 offset-md-1"><label class="col-form-label" for="試算_会員種別">会員種別</label></div>
            <div class="col-md-3">
                <select name="試算_会員種別" class="form-select" v-model="試算_会員種別">
                    <option type="text" v-for="kind in member_kind_list" :key="kind.code" :value="kind.name">{{ kind.name }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <div class="d-flex justify-content-end">
                    <span class="col-form-label"><i class="bi bi-currency-yen"></i>{{ this.calc年会費_月割() }}</span>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <span><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">従量課金</label></span>
        </div> -->

        <div class="row mt-5">
            <div class="col-1 offset-md-1 bg-white border-estimate-top border-estimate-bottom border-estimate-start border-estimate-end py-1 text-center"></div>
            <div class="col-md-9 bg-white border-estimate-top border-estimate-bottom border-estimate-end"><label class="col-form-label">ご入力情報</label></div>
        </div>
        <div class="row">
            <div class="col-1 offset-md-1 bg-white border-estimate-bottom border-estimate-start border-estimate-end py-1 text-center"><label class="col-form-label">1</label></div>
            <div class="col-md-3 bg-white border-estimate-bottom py-1 ps-4"><label class="col-form-label" for="試算_拠点数">拠点・支店の数</label></div>
            <div class="col-md-3 bg-white border-estimate-bottom py-1">
                <input type="number" min="0" v-model="試算_拠点数" class="form-control text-end">
            </div>
            <div class="col-md-3 bg-white border-estimate-bottom border-estimate-end py-1">
                <div class="d-flex justify-content-end">
                    <span class="col-form-label">{{ formatNumber(this.calc従量課金_拠点()) }}{{ yen_label }}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-1 offset-md-1 bg-white border-estimate-bottom border-estimate-start border-estimate-end py-1 text-center"><label class="col-form-label">2</label></div>
            <div class="col-md-3 bg-white border-estimate-bottom py-1 ps-4"><label class="col-form-label" for="試算_従業員数">利用者（役員・従業員）の人数</label></div>
            <div class="col-md-3 bg-white border-estimate-bottom py-1">
                <input type="number" min="0" v-model="試算_従業員数" class="form-control text-end">
            </div>
            <div class="col-md-3 bg-white border-estimate-end border-estimate-bottom py-1">
                <div class="d-flex justify-content-end">
                    <span class="col-form-label">{{ formatNumber(this.calc従量課金_従業員()) }}{{ yen_label }}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-1 offset-md-1 bg-white border-estimate-bottom border-estimate-start border-estimate-end py-1 text-center"><label class="col-form-label">3</label></div>
            <div class="col-md-3 bg-white border-estimate-bottom py-1 ps-4"><label class="col-form-label" for="試算_家族数">利用者家族の人数</label></div>
            <div class="col-md-3 bg-white border-estimate-bottom py-1">
                <input type="number" min="0" v-model="試算_家族数" class="form-control text-end">
            </div>
            <div class="col-md-3 bg-white border-estimate-bottom border-estimate-end py-1">
                <div class="d-flex justify-content-end">
                    <span class="col-form-label">{{ formatNumber(this.calc従量課金_家族()) }}{{ yen_label }}</span>
                </div>
            </div>
        </div>
        <div class="row" v-if="showLINE === 'true'">
            <div class="col-1 offset-md-1 bg-white border-estimate-bottom border-estimate-start border-estimate-end py-1 text-center"><label class="col-form-label">4</label></div>
            <div class="col-md-3 bg-white border-estimate-bottom py-1 ps-4"><label class="col-form-label" for="試算_LINE">LINE連携オプションの有無</label></div>
            <div class="col-md-3 bg-white border-estimate-bottom py-1">
                <select name="試算_会員種別" class="form-select" v-model="試算_LINE">
                    <option type="text" value="0">なし</option>
                    <option type="text" value="1">あり</option>
                </select>
            </div>
            <div class="col-md-3 bg-white border-estimate-bottom border-estimate-end py-1">
                <div class="d-flex justify-content-end">
                    <span class="col-form-label">{{ formatNumber(this.calc従量課金_LINE()) }}{{ yen_label }}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2 offset-md-7 bg-white border-estimate-start border-estimate-bottom py-1">
                <label class="col-form-label">合計</label>
            </div>
            <div class="col-md-2 bg-white border-estimate-bottom border-estimate-end py-1">
                <div class="d-flex justify-content-end">
                    <span class="col-form-label">{{ formatNumber(this.calc合計額()) }}{{ yen_label }}</span>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1 col-md">
                <label class="col-form-label">※従業課金は年度単位（10月～翌年9月）のため月割計算はございません。加入月に年度の費用が請求されます。</label>
            </div>
        </div>
        <div class="row mt-5">
            <div class="offset-md-1 col-md">
                <input type="button" class="btn btn-black px-5" value="閉じる" @click="closeWindow">
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { CommonNumberFormat } from '../../function/CommonNumberFormat.js';
import { useEnv } from '../../function/useEnv.js';
import { YEN_LABEL } from "@/constants";

export default {
    setup(props){
        const { VITE_ENABLE_LINE } = useEnv();
        const showLINE = ref(VITE_ENABLE_LINE);

        const 試算_拠点数 = ref(1);
        const 試算_従業員数 = ref(1);
        const 試算_家族数 = ref(0);
        const 試算_LINE = ref(0);

        const yen_label = ref(YEN_LABEL.yen_per_year);

        const formatNumber = (num) => {
            return CommonNumberFormat(num);
        }

        const calc従量課金_拠点 = () => {
            let return_val = 0;

            for (const item of props.pay_per_use_detail_branch) {
                if(Number(item.終了数) >= Number(試算_拠点数.value)){
                    //入力値が終了数以下の場合、このレコード分で終わる
                    return_val += (試算_拠点数.value - Number(item.開始数) + 1) * item.課金額;

                    return return_val;
                }else{
                    return_val += (item.終了数 - item.開始数 + 1) * item.課金額;
                }
            };

            return return_val;
        }

        const calc従量課金_従業員 = () => {
            let return_val = 0;

            for (const item of props.pay_per_use_detail_employee) {
                if(Number(item.終了数) >= Number(試算_従業員数.value)){
                    //入力値が終了数以下の場合、このレコード分で終わる
                    return_val += (試算_従業員数.value - Number(item.開始数) + 1) * item.課金額;

                    return return_val;
                }else{
                    return_val += (item.終了数 - item.開始数 + 1) * item.課金額;
                }
            };

            return return_val;
        }

        const calc従量課金_家族 = () => {
            let return_val = 0;

            for (const item of props.pay_per_use_detail_family) {
                if(Number(item.終了数) >= Number(試算_家族数.value)){
                    //入力値が終了数以下の場合、このレコード分で終わる
                    return_val += (試算_家族数.value - Number(item.開始数) + 1) * item.課金額;

                    return return_val;
                }else{
                    return_val += (item.終了数 - item.開始数 + 1) * item.課金額;
                }
            };

            return return_val;
        }

        const calc従量課金_LINE = () => {
            if(試算_LINE.value == 1){
                return props.pay_per_use_line;
            }else{
                return 0;
            }
        }

        const calc合計額 = () => {
            return Number(calc従量課金_拠点()) + Number(calc従量課金_従業員()) + Number(calc従量課金_家族()) + Number(calc従量課金_LINE());
        };

        const closeWindow = () => {
            console.log("test");
            window.close();
        }

        return{
            formatNumber,
            showLINE,

            試算_拠点数,
            試算_従業員数,
            試算_家族数,
            試算_LINE,

            yen_label,
            
            calc従量課金_拠点,
            calc従量課金_従業員,
            calc従量課金_家族,
            calc従量課金_LINE,
            calc合計額,

            closeWindow,
        };
    },
    props: {
        pay_per_use:{
            type: Object,
        },
        pay_per_use_detail_employee:{
            type: Array,
        },
        pay_per_use_detail_branch:{
            type: Array,
        },
        pay_per_use_detail_family:{
            type: Array,
        },
        pay_per_use_line: {
            type: Number,
        },
    }

};
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.border-estimate-top {
    border-top: 1px solid common_colors.colors(resilience-black);    
}

.border-estimate-bottom {
    border-bottom: 1px solid common_colors.colors(resilience-black);    
}

.border-estimate-start {
    border-left: 1px solid common_colors.colors(resilience-black);
}

.border-estimate-end {
    border-right: 1px solid common_colors.colors(resilience-black);
}

@media (max-width: 767px) {
    .fs-md-sm {
        font-size: 0.75em;
    }

    .table-detail td:nth-child(1),
    .table-detail td:nth-child(3),
    .table-detail td:nth-child(6)
    {
        width: 10%;
    }

    .table-detail td:nth-child(2),
    .table-detail td:nth-child(4),
    .table-detail td:nth-child(7){
        width: 15%;
    }

}

</style>